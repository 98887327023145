










































/* global FB fb */
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  onMounted,
  computed,
} from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomGraphic,
  AtomButton,
  AtomButtonSizeEnum,
} from "@/v2/new-design-system";
import { report } from "@chatfood/bug-reporter";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { facebookCommerceRepo } from "../../repo";
import { useFacebookApi } from "@/module/common";
import { useHelpers } from "@/v2/composable/use-helpers";
import { t } from "@/i18n";

const css = bemBuilder("facebook-commerce-start");

export default defineComponent({
  name: "FacebookCommerceStart",
  components: {
    AtomText,
    AtomButton,
    AtomGraphic,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  emits: {
    connected: () => true,
  },
  setup(props, { emit }) {
    const isLoading = ref(false);

    const business = computed(() =>
      useHelpers().getCurrentBusiness(props.businessId)
    );
    const businessLink = `${process.env.VUE_APP_WEBVIEW_URL}/${business.value.url}`;

    const items = [
      {
        type: "facebook",
        icon: "facebook",
        image: require("./assets/facebook-shop.png"),
        title: t("module.facebook_commerce.start.facebook_shop.title"),
        label: t("module.facebook_commerce.start.facebook_shop.label"),
        description: t(
          "module.facebook_commerce.start.facebook_shop.description"
        ),
      },
      {
        type: "instagram",
        icon: "instagram-v2",
        image: require("./assets/instagram-shop.png"),
        title: t("module.facebook_commerce.start.instagram_shop.title"),
        label: t("module.facebook_commerce.start.instagram_shop.label"),
        description: t(
          "module.facebook_commerce.start.instagram_shop.description"
        ),
      },
      {
        type: "facebook",
        icon: "facebook",
        image: require("./assets/facebook-ads.png"),
        title: t("module.facebook_commerce.start.facebook_ads.title"),
        label: t("module.facebook_commerce.start.facebook_ads.label"),
        description: t(
          "module.facebook_commerce.start.facebook_ads.description"
        ),
      },
    ];

    const { startFacebook, onFacebookLogin } = useFacebookApi();

    onMounted(startFacebook);

    function onConnecting() {
      onFacebookLogin(onInstalling, {
        scope: "manage_business_extension",
        // @ts-expect-error extras object is not typed from the LoginOptions
        extras: {
          setup: {
            external_business_id: props.businessId,
            timezone: business.value.timezone,
            currency: business.value.currency.code,
            business_vertical: "ECOMMERCE",
            domain: process.env.VUE_APP_WEBVIEW_URL,
          },
          business_config: {
            business: {
              name: business.value.name,
            },
            page_cta: {
              enabled: true,
              cta_button_text: "Buy Now",
              cta_button_url: businessLink,
              below_button_text: "Powered by ChatFood",
            },
            page_post: {
              enabled: true,
              cta_button_text: "Buy Now",
              cta_button_url: businessLink,
              title: "Order Food",
              subtitle: "Powered by ChatFood",
            },
            ig_cta: {
              enabled: true,
              cta_button_text: "Order Food",
              cta_button_url: businessLink,
            },
            messenger_menu: {
              enabled: true,
              cta_button_text: "Book Now",
              cta_button_url: businessLink,
            },
            thread_intent: {
              enabled: true,
              cta_button_url: businessLink,
            },
          },
          repeat: false,
        },
      });
    }

    async function onInstalling(response: fb.StatusResponse) {
      if (!response?.authResponse?.accessToken) {
        generalErrorToast(
          t("module.facebook_commerce.start.message_connection_canceled")
        );
        return;
      }

      try {
        isLoading.value = true;
        await facebookCommerceRepo.install({
          businessId: props.businessId,
          accessToken: response.authResponse.accessToken,
        });
        emit("connected");
      } catch (e) {
        report(e);
        generalErrorToast(
          t("module.facebook_commerce.start.message_failed_store_token")
        );
      } finally {
        isLoading.value = false;
      }
    }

    return {
      t,
      css,
      items,
      isLoading,
      onConnecting,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
    };
  },
});

import {
  IInstallMetaBusinessExtensionRequest,
  IInstallMetaBusinessExtensionBodyPayload,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "@/v2/repo/http";

export class InstallMetaBusinessExtensionError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: InstallMetaBusinessExtension");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

/**
 * {@link https://chatfood.stoplight.io/docs/chatfood-api-dashboard/branches/main/d736f8a16c6e9-install-meta-business-extension API Contract}
 */
export async function installMetaBusinessExtension(
  request: IInstallMetaBusinessExtensionRequest
): Promise<void> {
  try {
    const params: IInstallMetaBusinessExtensionBodyPayload = {
      access_token: request.accessToken,
    };

    await http.post(
      `v1/businesses/${request.businessId}/meta-business-extension/install`,
      params
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new InstallMetaBusinessExtensionError(e);
  }
}
